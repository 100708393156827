<template>
  <div>
    <div class="col-md-12 tw-pt-8">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("string.construnctionInformation") }}
          </h3>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table class="table-bordered table-striped table-condensed">
              <tbody>
                <tr>
                  <th style="width: 250px">
                    {{ $t("label.buildingAmount") }}
                  </th>
                  <td>{{ construction.nb_building }}</td>
                </tr>
                <tr>
                  <th>
                    {{ $t("label.nbUndergroundFloor") }}
                  </th>
                  <td>{{ construction.nb_underground_floor }}</td>
                </tr>
                <tr>
                  <th>
                    {{ $t("label.total_floors") }}
                  </th>
                  <td>{{ construction.nb_floor }}</td>
                </tr>
                <tr>
                  <th>
                    {{ $t("label.buildingHigh") }}
                  </th>
                  <td>{{ construction.high }}</td>
                </tr>
                <tr>
                  <th style="width: 180px">
                    {{ $t("label.total_construction_areas") }}
                  </th>
                  <td>{{ construction.construction_surface }}</td>
                </tr>
                <tr>
                  <th>
                    {{ $t("label.totalUndergroundConstructionAreas") }}
                  </th>
                  <td>{{ construction.surface_bottom_cell }}</td>
                </tr>
                <tr>
                  <th>
                    {{ $t("label.totalBuildingArea") }}
                  </th>
                  <td>{{ construction.construction_surface_can_processed }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 tw-pt-8">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("label.floorInformation") }}
          </h3>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table class="table-bordered table-striped table-condensed">
              <tbody>
                <tr v-for="floor in construction.floors" :key="floor.name">
                  <th>
                    {{ floor.name }} (ជាម៉ែត្រការ៉េ)
                  </th>
                  <td>{{ floor.total_construction_area }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConstructionInformation",
  props: {
    construction: Object
  },
}
</script>

<style scoped></style>
