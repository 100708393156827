<template>
  <div
    :id="uuid"
    class="modal fade"
    tabindex="-1"
    role="dialog"
  >
    <div
      class="modal-dialog"
      :class="{'modal-lg': big}"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header" />
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  model: {
    prop: 'isOpen',
    event: 'toggle'
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uuid: null
    }
  },
  watch: {
    isOpen(value) {
      if (value) {
        $('#' + this.uuid).modal('show')
      } else {
        $('#' + this.uuid).modal('hide')
      }
    }
  },
  created() {
    this.uuid = this.uuidv4()
  },
  mounted() {
    if (!this.isOpen) {
      $(".modal-backdrop.fade.in").remove()
    }
    const modal = $('#' + this.uuid)
    const self = this
    modal.on('show.bs.modal', function () {
      if (self.model !== true) {
        self.$emit('toggle', true)
      }
    })
    modal.on('hide.bs.modal', function () {
      if (self.model !== false) {
        self.$emit('toggle', false)
      }
    })
  },
  beforeDestroy() {
    const modal = $('#' + this.uuid)
    modal.off('show.bs.modal')
    modal.off('hide.bs.modal')
    if (this.isOpen) {
      $(".modal-backdrop.fade.in").remove()
    }
    $('#' + this.uuid).modal('dispose')
  },
  methods: {
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    }
  }
}
</script>

<style scoped>

</style>
