<template>
  <div class="col-md-12 tw-pt-8">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t("string.location") }}
        </h3>
      </div>
      <div
        id="map"
        style="width: 100%; height: 400px;"
        ></div>
      <modal
        id="3d-map-modal"
      >
        <template #header>
          Building Info
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
import 'leaflet/dist/leaflet.css'

export default {
  name: "LandMap3D",
  props: {
    wkb: String
  },
  mounted() {
    if(!this.wkb){
      return
    }
    const script = document.createElement('script')
    // script.src = "https://cdn.osmbuildings.org/4.1.2/OSMBuildings.js"
    script.src = '/js/OSMBuildings.js'
    script.async = true
    document.head.appendChild(script)
    script.onload = () => {
      var wkx = require('wkx')
      var Buffer = require('buffer').Buffer
      var wkbBuffer = new Buffer(this.wkb, 'hex')
      var geometry = wkx.Geometry.parse(wkbBuffer)
      const geoJsonData = geometry.toGeoJSON()
      if (geoJsonData.type === "Polygon") {
        this.paths = geoJsonData.coordinates[0].map(([lng, lat]) => ({
          lat,
          lng,
        }))
      } else if (geoJsonData.type === "MultiPolygon") {
        this.paths = geoJsonData.coordinates[0][0].map(([lng, lat]) => ({
          lat,
          lng,
        }))
      }
      var map = new window.OSMBuildings({
        container: 'map',
        position: this.center,
        zoom: 17,
        maxZoom: 20,
        tilt: 40,
        attribution: '© Data <a href="https://openstreetmap.org/copyright/">OpenStreetMap</a> © Map <a href="https://osmbuildings.org/copyright/">OSM Buildings</a>'
      })
      map.addMapTiles('https://tile.openstreetmap.org/{z}/{x}/{y}.png')
      map.addGeoJSONTiles('https://{s}.data.osmbuildings.org/0.2/59fcc2e8/tile/{z}/{x}/{y}.json')
      const geoJSON = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: geoJsonData,
            properties: {
              height: 50,
              isMainObject: true,
              name: "Location",
              color: "#c85f2e",
              roofMaterial: "tar_paper",
              roofColor: "#797a7d",
              roofHeight: 0,
              roofLevels: 0
            }
          },
        ],
      }
      map.addGeoJSON(geoJSON)
      map.on('pointerup', e => {
        if (e.features[0]) {
          if(e.features[0].properties.isMainObject){
            var properties = e.features[0].properties
            var popupContent = `
              Name: ${properties.name || 'Unknown'}<br>
              Height: ${properties.height || 'Unknown'}m
            `
            $('#3d-map-modal').find('.modal-body').html(popupContent);
            $('#3d-map-modal').modal('show')
          }
        }
      })
    }
    script.onerror = () => {
      console.error("Failed to load the script.")
    }
  },
  data() {
    return {
      path: [
        [104.917500953044, 11.568938901162 ],
        [104.917645398564, 11.5689595826553],
        [104.917687114538, 11.5689289997403],
        [104.91769473362, 11.5688785906283],
        [104.917608657646, 11.5688655652327],
        [104.9176137088, 11.5688325832207],
        [104.917699719293, 11.5688455993734],
        [104.917724319626, 11.5686828441545],
        [104.91739322872, 11.5686327383255],
        [104.917350069523, 11.568917301639],
        [104.917500167386, 11.5689387902659],
        [104.917500953044, 11.568938901162]
      ],
      paths: [
        { lng: 104.917500953044, lat: 11.568938901162 },
        { lng: 104.917645398564, lat: 11.5689595826553 },
        { lng: 104.917687114538, lat: 11.5689289997403 },
        { lng: 104.91769473362, lat: 11.5688785906283 },
        { lng: 104.917608657646, lat: 11.5688655652327 },
        { lng: 104.9176137088, lat: 11.5688325832207 },
        { lng: 104.917699719293, lat: 11.5688455993734 },
        { lng: 104.917724319626, lat: 11.5686828441545 },
        { lng: 104.91739322872, lat: 11.5686327383255 },
        { lng: 104.917350069523, lat: 11.568917301639 },
        { lng: 104.917500167386, lat: 11.5689387902659 },
        { lng: 104.917500953044, lat: 11.568938901162 },
      ],
    }
  },
  computed: {
    center() {
      const totalLat = this.paths.reduce((sum, point) => sum + point.lat, 0)
      const totalLng = this.paths.reduce((sum, point) => sum + point.lng, 0)

      return {
        latitude: totalLat / this.paths.length,
        longitude: totalLng / this.paths.length,
      }
    },
  },
  methods: {
  },
}
</script>
